export default defineNuxtRouteMiddleware(async (to, from) => {
  if (import.meta.server) return;

  if (to.path === "/login") return;

  const authStore = useAuthStore();
  if (!(await authStore.isAuthenticated)) {
    // TODO see why content is broken and needs refresh after navigating to login (external: true solves it)
    return navigateTo("/login", { external: true });
  }
});
